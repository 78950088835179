import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
// When the page loads, Stimulus looks for elements with data-controller attribute
// The data-controller="amount-picker" in amount_picker_component.html.erb tells Stimulus to connect that element to this controller
// The connect method is called automatically when the controller is connected.

  // This tells Stimulus to look for elements with:
  // - data-amount-picker-target="hangoutText"
  // - data-amount-picker-target="amountInput"
  // These elements will be accessible as this.hangoutTextTarget and this.amountInputTargets
  static targets = ["hangoutText", "amountInput"]

  // This tells Stimulus to look for a data attribute called data-amount-picker-hangout-amount-value
  // The value of this attribute will be automatically stored in this.hangoutAmountValue
  // In amount_picker_component.html.erb, we set it like this: data-amount-picker-hangout-amount-value="<%= App::MembershipFormComponent::HANGOUT_AMOUNT %>"
  static values = { hangoutAmount: Number }

  // As soon as Stimulus connects the controller, it automatically calls the connect method.
  connect() {
    // When the controller connects, we immediately update the hangout text visibility
    this.updateHangoutText()
  }

  // This method checks if the hangout amount is selected and shows/hides the hangout text accordingly
  updateHangoutText() {
    // Find the selected radio button (the one that's checked) within amount_picker_component.html.erb and get its value
    const selectedAmount = parseInt(this.amountInputTargets.find(input => input.checked).value)

    // Check if the selected amount is equal to the hangout amount (25000)
    const isHangoutAmount = selectedAmount === this.hangoutAmountValue

    // If we have a hangout text element... 
    if (this.hasHangoutTextTarget) {
      // Toggle its visibility based on whether the hangout amount is selected
      // If isHangoutAmount is true, we remove the 'hidden' class
      // If isHangoutAmount is false, we add the 'hidden' class
      this.hangoutTextTarget.classList.toggle('hidden', !isHangoutAmount)
    }
  }

  // This method is called when an amount radio button is clicked
  // It's connected to the radio buttons via the data-action attribute in amount_picker_component.html.erb
  amountChanged() {
    // When the amount changes, we update the hangout text visibility
    this.updateHangoutText()
  }
}