import { Controller } from '@hotwired/stimulus';

const checkedAmountSelector       = 'input[type="radio"][name$="[amount]"]:checked';
const annualMembershipMultiplier  = 9.6;
const workingButtonLabel          = 'Processing...';

// --------------------------------------------------------
// Utilities
// --------------------------------------------------------

function formattedAmount(amount, isAnnual) {
  const dollarAmount    = parseInt(amount) / 100;
  const multiplier      = isAnnual ? annualMembershipMultiplier : 1;
  const totalAmount     = dollarAmount * multiplier;
  
  return totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets = ['amountPicker', 'donationTypeCheckbox', 'submitButton'];

  static values = {
    buttonDisabled: { type: Boolean, default: false },
    buttonLabelBase:      String,
    buttonText:           String,
    selectedAmount:       String,
    isAnnualSubscription: Boolean,
    confirmation:         Boolean,
    stripeTokenId:        String
  }

  amountPickerTargetConnected(picker) {
    const defaultValue = picker.querySelector(checkedAmountSelector)?.value;
    
    if(defaultValue) {
      this.selectedAmountValue = defaultValue;
    }
  }

  onAmountChanged(event) {
    this.selectedAmountValue = event.target.value;
  }

  onDonationTypeChanged(event) {
    this.isAnnualSubscriptionValue = event.target.checked;
  }

  onConfirmationChanged(event) {
    this.confirmationValue = event.target.checked;
  }

  onTokenRequested() {
    this.stripeTokenIdValue = null;
  }

  onTokenReceived(evt) {
    this.stripeTokenIdValue = evt.detail?.token?.id;
  }

  onSubmit(evt) {
    if(this.buttonDisabledValue) {
      evt.preventDefault();
    }

    this.buttonDisabledValue  = true;
    this.buttonTextValue      = workingButtonLabel;
  }

  buttonDisabledValueChanged(disabled) {
    this.submitButtonTarget.disabled = disabled;
  }

  selectedAmountValueChanged(amount) {
    if(amount) {
      const currencyAmount = formattedAmount(amount, this.isAnnualSubscriptionValue);
      this.updateButtonLabel(currencyAmount);
    }
  }

  isAnnualSubscriptionValueChanged(isAnnual) {
    if(this.selectedAmountValue) {
      const currencyAmount = formattedAmount(this.selectedAmountValue, isAnnual);
      this.updateButtonLabel(currencyAmount);
    }
  }

  confirmationValueChanged() {
    this.updateButtonDisabledState();
  }

  stripeTokenIdValueChanged() {
    this.updateButtonDisabledState();
  }

  buttonTextValueChanged(label) {
    this.submitButtonTarget.value = label;
  }

  updateButtonLabel(formattedAmount) {
    this.buttonTextValue = `${this.buttonLabelBaseValue} for ${formattedAmount}`;
  }

  updateButtonDisabledState() {
    const enabled = this.confirmationValue && this.stripeTokenIdValue;
    this.buttonDisabledValue = !enabled;
  }
}